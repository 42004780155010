// list of numerical words. 
// *NOTE - The npm package doesn't currently support numbers greater than a trillion when converting into digits
export const DIGITS = [
    'hundred', 
    'thousand', 
    'million', 
    'billion', 
    'trillion'
];

export const MATERIALS = [
    'ballast',
    'bark',
    'bark', 
    'cement',
    'chippings',
    'cobbles',
    'hardstone',
    'limestone',
    'mortar',
    'post mix',
    'sand',
    'shingle',
    'slate',
    'specialist ',
    'tarmac',
    'type 1',
    'blocks',
    'brick',
    'bricks', 
    'cement',
    'chippings', 
    'hardcore',
    'sand', 
    'slabs', 
    'tile'
];

export const MANUFACTURERS = [
    'aggregate industries',
    'bea',
    'border aggregates',
    'bourne amenity',
    'breedon',
    'clubb sand & gravel',
    'moorhouse sand pits',
    'nepicar sands',
    'remix',
    'rugby',
    'stema mibau & aggregate industries',
    'tarmac',
    'ibstock',
    'lbc',
    'sandtoft',
    'vandersanden',
    'wienerberger'
];

export const UNITS = [
    'm', 
    'm squared', 
    'm cubed', 
    'kg', 
    'tonnes', 
    'lengths'
];

export const NEXT_CONDITIONS = [
    "next", 
    "Next", 
    "NEXT"
];

export const FILTERED_PRODUCTS = [
    'Acid', 
    'Adhesive', 
    'Blade', 
    'Bolster', 
    'Brush', 
    'channel', 
    'Channel', 
    'Cleaner', 
    'Duct', 
    'Dye', 
    'Edger', 
    'Gun', 
    'Hammer', 
    'Hod', 
    'Jointer', 
    'Nailer', 
    'Raker', 
    'Reinforcement', 
    'Tape', 
    'Trowel', 
];
